<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="状态">
          <el-select v-model="searchForm.status" placeholder="请选择状态" size="small" clearable>
            <el-option label="已整改" :value="1"></el-option>
            <el-option label="未整改" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单位名称">
          <el-input v-model="searchForm.unitName" placeholder="请输入单位名称" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" size="small" @click="open('report')" icon="el-icon-upload2">隐患上报</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="dangerList" stripe style="width: 100%" height="100%">
        <el-table-column prop="unitName" label="单位名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="legalName" label="法人姓名" show-overflow-tooltip width="100">
        </el-table-column>
        <el-table-column prop="legalPhone" label="法人电话" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="type" label="隐患事件类型" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.type===0?'占用，堵塞，封闭等':'其他'}}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" show-overflow-tooltip width="80">
          <template slot-scope="scope">
            <el-tag type="success" size="small" v-show="scope.row.status===1">已整改</el-tag>
            <el-tag type="danger" size="small" v-show="scope.row.status===0">未整改</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="隐患内容" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="startTime" label="隐患出现日期" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.startTime|timeFormat}}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip content="消除" placement="top" effect="dark">
              <el-button type="danger" size="small" circle icon="el-icon-delete" @click="clearDanger(scope.row)" :disabled="scope.row.status===1"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 隐患上报弹窗 -->
    <el-dialog title="隐患上报" :visible.sync="reportShow" width="40%" top="3%" v-dialogDrag>
      <el-form :model="report" ref="reportForm" :rules="rules" label-width="100px">
        <el-form-item label="隐患单位" prop="unitId">
          <el-select v-model="report.unitId" placeholder="请选择所在单位" clearable filterable remote :remote-method="remoteMethod" style="float:left">
            <el-option v-for="(item,i) in unitOptions" :key="i" :label="item.unitName" :value="item.id">
              <span style="float: left">{{ item.unitName }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px;margin-left:3px;">{{ item.address }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="隐患出现日期" prop="startTime">
          <el-date-picker v-model="report.startTime" type="date" placeholder="选择日期" style="float:left">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="隐患状态" prop="status">
          <el-select v-model="report.status" placeholder="请选择状态" clearable style="float:left">
            <el-option label="已整改" :value="1"></el-option>
            <el-option label="未整改" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="隐患类型" prop="type">
          <el-select v-model="report.type" placeholder="请选择类型" clearable style="float:left">
            <el-option label="占用，堵塞，封闭等" :value="1"></el-option>
            <el-option label="其他" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="隐患内容" prop="status">
          <el-input type="textarea" :rows="4" v-model="report.content" placeholder="请输入内容" :show-word-limit="true" :maxlength="200">
          </el-input>
        </el-form-item>
        <el-form-item label="附件">
          <el-upload ref="upload" :file-list="fileList" :headers="{Authorization:token}" action="http://jaxf.jjxsw.vip:9002/app-version/uploadFile" :on-success="getUnitImage" :on-error="error" :limit="1" list-type="picture-card" :on-preview="handlePictureCardPreview">
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog title="隐患图片" :visible.sync="photoShow" :modal-append-to-body="false">
            <img width="100%" :src="photoUrl" alt="">
          </el-dialog>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="reportShow = false">取消</el-button>
        <el-button type="primary" @click="reportDanger">上报</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentPage: 1,
      total: 0,
      dangerList: [],
      typeList: [],
      searchForm: {},
      report: {},
      reportShow: false,
      photoShow: false,
      photoUrl: '',
      unitOptions: [],
      fileList: [],
      token: '',
      rules: {
        unitId: [
          { required: true, message: '请选择单位', trigger: 'change' }
        ],
        status: [
          { required: true, message: '请选择状态', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.token = sessionStorage.getItem('token')
    this.initTable()
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    async initTable () {
      const data = {}
      if (this.searchForm.unitName) {
        data.unitName = this.searchForm.unitName
      }
      data.status = this.searchForm.status
      data.pageNo = this.currentPage
      data.pageSize = 10
      const { data: result } = await this.$axios.get('/hiddenEvent/list', { params: data })
      if (result.code === 200) {
        this.dangerList = result.data.result
        this.total = result.data.total
      } else {
        this.$message.error(result.msg)
      }
    },
    // 清除隐患
    async clearDanger (row) {
      const confirm = await this.$confirm('此操作将永久清除该隐患, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.post(`/hiddenEvent/${row.id}`)
        if (result.code === 200) {
          this.$message.success('清除成功')
          this.initTable()
        } else {
          this.$message.success(result.msg)
        }
      }
    },
    open (flag) {
      switch (flag) {
        case 'report':
          this.reportShow = true
          break
      }
    },
    // 隐患上报
    reportDanger () {
      this.$refs.reportForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.post('/hiddenEvent/add', this.report)
          if (result.code === 200) {
            this.initTable()
            this.reportShow = false
            this.$message.success('上报成功')
          } else {
            this.$message.error(result.message)
          }
        }
      })
    },
    // 查看图片
    handlePictureCardPreview (file) {
      this.photoUrl = file.url
      this.photoShow = true
    },
    getUnitImage (res, file, fileList) {
      if (res.code === 200) {
        this.report.appendix = res.data
      }
    },
    error (err, file, fileList) {
      this.$message.error(err)
    },
    async remoteMethod (query) {
      const { data: result } = await this.$axios.get('/unit/listAllUnit', { params: { unitName: query } })
      if (result.code === 200) {
        this.unitOptions = result.data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}
.dialog {
  margin: 0;
  padding: 10px;
  ::v-deep  .el-dialog__header {
    display: none !important;
  }
}
.demo-table-expand {
  font-size: 0;
  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    ::v-deep  label {
      color: #99a9bf !important;
    }
  }
}
::v-deep  .el-table__expanded-cell {
  padding: 0 50px;
}
</style>
